// model
import { recordType, findRecordTypeIcon } from "@/model/record/recordModel";
import {
  documentColorClass,
  folderColorClass
} from "@/model/folder/folderModel";
import { findFileTypeIcon } from "@/model/record/fileModel";

// mixins
import { menuItemSettingsMixin } from "../base/settings/menuItemSettingsMixin";

export const recordIconMixin = {
  mixins: [menuItemSettingsMixin],
  methods: {
    /**
     * Get record Icon name
     * @param {{recordTypeId:number, children:number, extension:string}} record
     * @return {string|string}
     */
    recordIcon(record) {
      if (!record) {
        return undefined;
      }
      switch (record.recordTypeId) {
        // case recordType.FOLDER:
        // case recordType.DOCUMENT: {
        //   return (record?.children ?? 0) > 0
        //     ? iconFolderPlus
        //     : findRecordTypeIcon(type);
        // }
        // case recordType.SECTION: {
        //   return (record?.children ?? 0) > 0
        //     ? iconAgendaSectionPlus
        //     : findRecordTypeIcon(type);
        // }
        case recordType.FILE: {
          return findFileTypeIcon(record.extension);
        }
        default: {
          return findRecordTypeIcon(record.recordTypeId);
        }
      }
    },

    /**
     * Get record Type Icon
     * @param {Number|number} typeId
     * @return {string}
     */
    recordTypeIcon(typeId) {
      return findRecordTypeIcon(typeId);
    },

    /**
     * Get record Icon Color Class
     * @param {{recordTypeId:number}} record
     * @return {undefined|string}
     */
    recordIconColorClass(record) {
      return record
        ? this.recordTypeIconColorClass(
            record?.recordTypeId ?? recordType.RECORD
          )
        : undefined;
    },

    /**
     * record Type Icon Color Class
     * @param {Number|number} typeId
     * @return {undefined|string}
     */
    recordTypeIconColorClass(typeId) {
      switch (typeId) {
        case recordType.FOLDER:
        case recordType.MEETING:
        case recordType.SECTION:
        case recordType.ITEM: {
          return folderColorClass;
        }
        case recordType.DOCUMENT: {
          return documentColorClass;
        }
        case recordType.RECORD: {
          return undefined;
        }
        default: {
          return this.menuItemColorClass;
        }
      }
    }
  }
};
