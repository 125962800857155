<template>
  <v-badge :value="isShortcut" bottom left overlap bordered>
    <!-- badge icon -->
    <template v-slot:badge>
      <span v-html="symbolShortcut" v-if="isShortcut"></span>
      <!-- remarks: using font-awesome, since v-badge :icon has issues at production -->
      <!--              <font-awesome-icon-->
      <!--                :icon="fontAwesomeSolidIcon(fasIcon.link)"-->
      <!--              ></font-awesome-icon>-->
    </template>
    <!-- badge component -->
    <template v-slot:default>
      <record-badge-base
        :record="record"
        :badge-overlap="badgeOverlap"
        :badge-bordered="badgeBordered"
        :disabled="disabled"
        :info-only="infoOnly"
        :icon-color-class="iconColorClass"
      >
        <!-- button -->
        <template v-slot:default>
          <slot name="default">
            <v-btn small icon :to="to" @click="onClick">
              <v-icon :color="computedIconColorClass">{{
                recordIcon(record)
              }}</v-icon>
            </v-btn>
          </slot>
        </template>
        <!-- tooltip -->
        <template v-slot:tooltip>
          <slot name="tooltip"></slot>
        </template>
      </record-badge-base>
    </template>
  </v-badge>
</template>

<script>
import { recordIconMixin } from "@/mixins/shared/record/recordIconMixin";
import { recordBadgeMixin } from "@/mixins/shared/record/recordBadgeMixin";

export default {
  name: "RecordBadgeButton",
  mixins: [recordIconMixin, recordBadgeMixin],
  components: {
    RecordBadgeBase: () => import("@/components/record/RecordBadgeBase")
  },
  props: {
    /**
     * Navigate to
     */
    to: undefined
  }
};
</script>
