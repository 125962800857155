import {
  Color,
  colorMD,
  variantNumber,
  variantType
} from "@/design/colors/Color";

/**
 * Get Material Design folder Color
 * @returns {{name:string, variant: string, variantNumber:number}}
 */
const folderColor = new Color(
  colorMD.yellow,
  variantType.darken,
  variantNumber.n2
);

/**
 * Get Material Design compound document Color
 */
const documentColor = new Color(
  colorMD.indigo,
  variantType.darken,
  variantNumber.n1
);

/**
 * Folder Color Class
 * @returns {string|*}
 */
const folderColorClass = folderColor.getClassColor();

/**
 * Compound document Color Class
 * @type {string|*}
 */
const documentColorClass = documentColor.getClassColor();

export { folderColor, documentColor, folderColorClass, documentColorClass };
